function Missed() {
  return (
    <div>
      <box className="DefaultBox">
        <p class="DefaultText">
          <br></br>Wil je meer weten over wat er aan komt? Schrijf je in op de nieuwsbrief, of volg ons op facebook of instagram! <br></br>
          <br></br>
          <br></br>2024
          <br></br>
          <br></br>
          <li>Sophie Verbruggen - Spintronics</li>
          <li>Jonas Verbruggen - Analoge signalen genereren van een dc bron</li>
          <li>Jan Lemeire - Van aap tot beschaving</li>
          <li>Krijn de Vries - Speciale relativiteitstheorie</li>
          <li>Chiara van Remmen - Burnout</li>
          <li>Willem Van Geyseghem - Hoog performante teams en organisaties</li>
          <li>Iedereen Felix - wetenschappelijk zoektocht, een samenwerking van Steamwork, VUB en We-IT</li>
          <li>Krijn de Vries - Hoe gebruiken we de kleinste deeltjes om de grootste objecten in ons universum te bestuderen?</li>
          <br></br>
          <br></br>2023
          <br></br>
          <br></br>
          <li>Dag van de Wetenschap druivenstreek - een samenwerking van Steamwork, VUB en We-IT</li>
          <li>Serge Muyldermans - Bewerken van genen van gewassen of dieren. Hoe werkt dat? Is dat gevaarlijk?</li>
          <li>Stijn Ally - Artificiële Intelligentie, offshore windparken en de integratie met waterstofproductie installaties</li>
          <li>Ben Vanachter - Muziek van Edison tot Stromae, een trillende reis door de geschiedenis van geluidsopnames</li>
          <li>Daan Vandervelde, Krijn Devries, Sophie Verbruggen & Willem Van Geyseghem - Lichaamstaal</li>
          <li>Nick van Eijndhoven - Speurtocht naar de bouwstenen van het heelal - deeltjesfysica</li>
          <li>Krijn Devries - Kosmische straling vangen in Groenland</li>
          <li>Jonas Verbruggen - Analoge systemen en hoe maak je een equalizer</li>
          <li>Koen Milisen - Valpartijen bij ouderen: is preventie mogelijk?</li>
          <li>Daan Vandervelde - 3D tekenen en printen</li>
          <li>Ike Picone - Sociale media en hun algoritmes</li>
          <li>Jan Kunnen - Marketing op sociale media</li>
          <br></br>
          <br></br>2022
          <br></br>
          <br></br>
          <li>Jeroen De Man - Introductie heelkundige operaties</li>
          <li>
            Willem Van Geyseghem - Serious games over samenwerken en
            effectiviteit
          </li>
          <li>
            Daan Vandervelde, Krijn Devries, Parmjeet Gurmeet & Willem Van
            Geyseghem - Vrouwen in de wetenschap
          </li>
          <li>
            Herman Baeyens van Druifkracht - Emissies en emissiereductie in de
            druivenstreek
          </li>
          <li>
            Krijn Devries, Yarno Merckx en Karel Rowies - Ons universum,
            deeltjesfysica en astrofotografie{" "}
          </li>
          <li>
            Erich Reiter (SAY It Labs) - Spraaherkenning voor mensen met een
            spraakgebrek
          </li>
          <li>Aisha Cortoos (Brainsewise) - Slaap</li>
          <li>
            Inne Vanderkelen - Het klimaat, de drijvers en oorzaken van
            klimaatverandering
          </li>
          <li>
            Laora Mastari - Gender, of hoe groepsdynamiek gender conformisme
            vormt onder adolescenten
          </li>
          <li>Daan Vandervelde - Arduino introductie</li>
          <li>Sophie Verbruggen & Jan Kunnen - Legocompetitie teambuilding </li>
          <li>
            Vincent Renkens - Hoe wordt machine learning gebruikt voor de AI van
            een personal assistent (zoals Siri)
          </li>
          <br></br>2021
          <br></br>
          <br></br>
          <li>Bram Vanderborght - Bezoek VUB Brubotics lab</li>
          <li>Sophie & Jonas Verbruggen over weerkunde</li>
          <li>Tom Poppe over Crypto currencies</li>
          <li>Sophie Verbruggen & Jan Kunnen - Workshop met OZO bots</li>
          <li>Jeroen De Man over Self-determination theorie</li>
          <li>Aaron Munsters - Web development met React en NodeJS</li>
          <li>Daan Vandervelde over de uitwerpselen van de wombat</li>
          <li>Pieter Muyldermans over de werking van vaccins</li>
          <br></br>2020
          <br></br>
          <br></br>
          <li>We-IT onderzoek over Python App development voor Android</li>
          <li>
            Jan Kunnen over kinderprogrammeertalen, programmeren met blokken
            (code.org)
          </li>
          <li>De grote online We-IT Quiz </li>
          <li>Krijn Devries over de leegheid van de ruimte</li>
          <li>
            Sophie Verbruggen over het effect van melk op spierrecuperatie
          </li>
          <li>Willem Van Geyseghem over leiderschap en motivatie</li>
          <li>Karel Rowies over ruimterakketen</li>
          <li>Jan Kunnen over donkere materie</li>
          <li> Vandervelde over ingenieuze technieken in ziekenhuizen</li>
          <li>Jan Kunnen over digitale onderwijstools</li>
        </p>
      </box>
    </div>
  );
}

export default Missed;
